import { createClient } from "graphql-ws";
import initLogger from "./log";

const logger = initLogger(process.env.LOGTAIL_SOURCE_TOKEN);

import {
  DeletedQuestion,
  ExcludeProposal,
  GetQuestionQuery,
  GoToRatingPhase,
  GoToResultPhase,
  PostNewProposal,
  PostNewQuestion,
  PostRatings,
  SubscribeToNewProposals,
  SubscribeToNewRatings,
  SubscribeToPhaseChanges,
} from "./queries";

const client = createClient({
  url: process.env.GRAPHQL_WEBSOCKET_ENDPOINT,
});

export default function init(output) {
  const subscriptions = {};

  return async (incomingMessage) => {
    switch (incomingMessage.tag) {
      case "LogError":
        logger.error("LogError", incomingMessage.value);
        break;

      case "GetQuestion": {
        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          body: JSON.stringify({
            query: GetQuestionQuery,
            operationsName: "GetQuestion",
            variables: {
              id: incomingMessage.value,
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("GetQuestion", { id: incomingMessage.value, errors });
          break;
        }

        if (!errors && data?.questions_by_pk) {
          const {
            id,
            title,
            phase,
            author,
            description,
            createdAt,
            expiresAt,
            proposalsEndedAt,
            ratingEndedAt,
            proposals,
            ratings,
            phaseCodePublic,
          } = data.questions_by_pk;

          const question = {
            id,
            title,
            phase,
            author,
            description,
            createdAt: new Date(createdAt).getTime(),
            expiresAt: new Date(expiresAt).getTime(),
            proposalsEndedAt: proposalsEndedAt
              ? new Date(proposalsEndedAt).getTime()
              : null,
            ratingEndedAt: ratingEndedAt
              ? new Date(ratingEndedAt).getTime()
              : null,
            proposals: proposals.map((proposal) => ({
              ...proposal,
              createdAt: new Date(proposal.createdAt).getTime(),
            })),
            ratings: ratings.map((rating) => ({
              ...rating,
              proposalId: rating.proposalId ?? "DEFAULT",
              createdAt: new Date(rating.createdAt).getTime(),
            })),
            phaseCodePublic,
          };

          output("ReceivedQuestion", question);
          break;
        }

        output("GoToNotFound");
        break;
      }

      case "PostNewQuestion": {
        const {
          title: submittedTitle,
          author: submittedAuthor,
          description: submittedDescription,
          phaseCode: submittedPhaseCode,
        } = incomingMessage.value;

        const codes = {};
        if (submittedPhaseCode) {
          codes.phaseCode = await sha256(submittedPhaseCode);
          codes.phaseCodePublic = await sha256(codes.phaseCode);
        }

        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          headers: {
            "X-Hasura-Phase-Code": codes.phaseCode || null,
          },
          body: JSON.stringify({
            query: PostNewQuestion,
            operationsName: "PostNewQuestion",
            variables: {
              title: submittedTitle,
              author: submittedAuthor,
              description: submittedDescription,
              ...codes,
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("PostNewQuestion", { errors });
          break;
        }

        if (!errors && data?.insert_questions_one) {
          const {
            id,
            title,
            author,
            description,
            phase,
            createdAt,
            expiresAt,
            phaseCodePublic,
          } = data.insert_questions_one;

          const newQuestion = {
            id,
            title,
            author,
            description,
            phase,
            createdAt: new Date(createdAt).getTime(),
            expiresAt: new Date(expiresAt).getTime(),
            proposals: [],
            ratings: [],
            phaseCodePublic,
          };

          output("ReceivedQuestion", newQuestion);
        }
        break;
      }

      case "PostNewProposal": {
        const {
          value: {
            questionId: submittedQuestionId,
            title: submittedTitle,
            description: submittedDescription,
          },
        } = incomingMessage;

        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          body: JSON.stringify({
            query: PostNewProposal,
            operationsName: "PostNewProposal",
            variables: {
              title: submittedTitle,
              description: submittedDescription,
              questionId: submittedQuestionId,
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("PostNewProposal", { id: submittedQuestionId, errors });
          break;
        }

        if (!errors && data?.insert_proposals_one) {
          const { id, title, description, isExcluded, createdAt } =
            data.insert_proposals_one;

          const newProposal = {
            id,
            title,
            isExcluded,
            ...(description && { description }),
            createdAt: new Date(createdAt).getTime(),
          };

          output("ReceivedPublishedProposal", newProposal);
        }
        break;
      }

      case "ExcludeProposal": {
        const { proposalId, isExcluded } = incomingMessage.value;

        await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          body: JSON.stringify({
            query: ExcludeProposal,
            operationsName: "ExcludeProposal",
            variables: {
              id: proposalId,
              isExcluded,
            },
          }),
        });
        break;
      }

      case "GoToRatingPhase": {
        const { questionId, phaseCode } = incomingMessage.value;

        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          headers: {
            "X-Hasura-Phase-Code": (await sha256(phaseCode)) || "",
          },
          body: JSON.stringify({
            query: GoToRatingPhase,
            operationsName: "GoToRatingPhase",
            variables: {
              id: questionId,
              phase: "RATING",
              proposalsEndedAt: new Date(),
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("GoToRatingPhase", { id: questionId, errors });
          break;
        }

        if (!errors && data?.update_questions_by_pk) {
          const question = data.update_questions_by_pk;
          question.createdAt = new Date(question.createdAt).getTime();
          question.expiresAt = new Date(question.expiresAt).getTime();
          question.proposalsEndedAt = question.proposalsEndedAt
            ? new Date(question.proposalsEndedAt).getTime()
            : null;
          question.ratingEndedAt = question.ratingEndedAt
            ? new Date(question.ratingEndedAt).getTime()
            : null;
          question.proposals = question.proposals.map((proposal) => ({
            ...proposal,
            createdAt: new Date(proposal.createdAt).getTime(),
          }));
          question.ratings = question.ratings.map((rating) => ({
            ...rating,
            proposalId: rating.proposalId ?? "DEFAULT",
            createdAt: new Date(rating.createdAt).getTime(),
          }));

          output("ReceivedQuestion", question);
        }
        break;
      }

      case "GoToResultPhase": {
        const { questionId, phaseCode } = incomingMessage.value;

        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          headers: {
            "X-Hasura-Phase-Code": (await sha256(phaseCode)) || "",
          },
          body: JSON.stringify({
            query: GoToResultPhase,
            operationsName: "GoToResultPhase",
            variables: {
              id: questionId,
              phase: "RESULT",
              ratingEndedAt: new Date(),
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("GoToResultPhase", { id: questionId, errors });
          break;
        }

        if (!errors && data?.update_questions_by_pk) {
          const question = data.update_questions_by_pk;
          question.createdAt = new Date(question.createdAt).getTime();
          question.expiresAt = new Date(question.expiresAt).getTime();
          question.proposalsEndedAt = question.proposalsEndedAt
            ? new Date(question.proposalsEndedAt).getTime()
            : null;
          question.ratingEndedAt = question.ratingEndedAt
            ? new Date(question.ratingEndedAt).getTime()
            : null;
          question.proposals = question.proposals.map((proposal) => ({
            ...proposal,
            createdAt: new Date(proposal.createdAt).getTime(),
          }));
          question.ratings = question.ratings.map((rating) => ({
            ...rating,
            proposalId: rating.proposalId ?? "DEFAULT",
            createdAt: new Date(rating.createdAt).getTime(),
          }));

          output("ReceivedQuestion", question);
        }
        break;
      }

      case "PostRatings": {
        const { questionId, author, ratings } = incomingMessage.value;

        const preparedRatings = ratings.map(([proposalId, points]) => ({
          author,
          points,
          proposalId: proposalId === "DEFAULT" ? null : proposalId,
          questionId,
        }));

        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          body: JSON.stringify({
            query: PostRatings,
            operationsName: "PostRatings",
            variables: {
              ratings: preparedRatings,
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("PostRatings", { id: questionId, errors });
          break;
        }

        if (!errors && data?.insert_ratings) {
          const newRatings = data.insert_ratings.returning.map((rating) => ({
            ...rating,
            proposalId: rating.proposalId ?? "DEFAULT",
            createdAt: new Date(rating.createdAt).getTime(),
          }));

          output("ReceivedPublishedRating", newRatings);
        }
        break;
      }

      case "DeleteQuestion": {
        const { questionId, phaseCode } = incomingMessage.value;

        const response = await fetch(process.env.GRAPHQL_HTTP_ENDPOINT, {
          method: "POST",
          headers: {
            "X-Hasura-Phase-Code": (await sha256(phaseCode)) || "",
          },
          body: JSON.stringify({
            query: DeletedQuestion,
            operationsName: "DeletedQuestion",
            variables: {
              id: questionId,
            },
          }),
        });

        const { errors, data } = await response.json();

        if (errors && errors.length >= 1) {
          output("ReceivedError", errors[0].message);
          logger.error("DeleteQuestion", { id: questionId, errors });
          break;
        }

        if (!errors && data?.delete_questions_by_pk) {
          output("DeletedQuestion");
        }
        break;
      }

      case "SubscribeToPhaseChanges": {
        if (subscriptions.SubscribeToPhaseChanges) {
          break;
        }

        const questionId = incomingMessage.value;

        const handlePhaseChange = (response) => {
          if (!response) {
            return;
          }
          const data = response?.data?.questions_by_pk;
          if (data) {
            const sanitizedData = {
              proposalsEndedAt: data.proposalsEndedAt
                ? new Date(data.proposalsEndedAt).getTime()
                : null,
              ratingEndedAt: data.ratingEndedAt
                ? new Date(data.ratingEndedAt).getTime()
                : null,
            };
            output("ReceivedPhaseChange", sanitizedData);
          } else {
            output("QuestionDeleted");
          }
        };

        subscriptions.SubscribeToPhaseChanges = client.subscribe(
          {
            query: SubscribeToPhaseChanges,
            variables: { id: questionId },
          },
          {
            next: handlePhaseChange,
            error: console.error,
            complete: handlePhaseChange,
          },
        );
        break;
      }

      case "SubscribeToNewProposals": {
        if (subscriptions.SubscribeToNewProposals) {
          break;
        }

        const questionId = incomingMessage.value;

        const handleIncomingProposals = (response) => {
          if (!response) {
            return;
          }
          const data = response?.data?.questions_by_pk;

          if (!data) {
            return;
          }

          const { proposals } = data;

          const updatedProposals = proposals.map((proposal) => ({
            ...proposal,
            createdAt: new Date(proposal.createdAt).getTime(),
          }));

          output("ReceivedNewProposals", updatedProposals);
        };

        subscriptions.SubscribeToNewProposals = client.subscribe(
          {
            query: SubscribeToNewProposals,
            variables: { id: questionId },
          },
          {
            next: handleIncomingProposals,
            error: console.error,
            complete: handleIncomingProposals,
          },
        );
        break;
      }

      case "SubscribeToNewRatings": {
        if (subscriptions.SubscribeToNewRatings) {
          break;
        }

        const questionId = incomingMessage.value;

        const handleIncomingRatings = (response) => {
          if (!response) {
            return;
          }
          const data = response?.data?.questions_by_pk;

          if (!data) {
            return;
          }

          const { ratings } = data;

          const updatedRatings = ratings.map((rating) => ({
            ...rating,
            proposalId: rating.proposalId ?? "DEFAULT",
            createdAt: new Date(rating.createdAt).getTime(),
          }));

          output("ReceivedNewRatings", updatedRatings);
        };

        subscriptions.SubscribeToNewRatings = client.subscribe(
          {
            query: SubscribeToNewRatings,
            variables: { id: questionId },
          },
          {
            next: handleIncomingRatings,
            error: console.error,
            complete: handleIncomingRatings,
          },
        );
        break;
      }

      case "UnsubscribeFromPhaseChanges": {
        if (!subscriptions.SubscribeToPhaseChanges) {
          return;
        }
        subscriptions.SubscribeToPhaseChanges();
        delete subscriptions.SubscribeToPhaseChanges;
        break;
      }

      case "UnsubscribeFromNewProposals": {
        if (!subscriptions.SubscribeToNewProposals) {
          return;
        }
        subscriptions.SubscribeToNewProposals();
        delete subscriptions.SubscribeToNewProposals;
        break;
      }

      case "UnsubscribeFromNewRatings": {
        if (!subscriptions.SubscribeToNewRatings) {
          return;
        }
        subscriptions.SubscribeToNewRatings();
        delete subscriptions.SubscribeToNewRatings;
        break;
      }

      case "HashPhaseCode": {
        const text = incomingMessage.value;
        const hash = await sha256(await sha256(text));

        output("ReceivedHashedPhaseCode", hash);
        break;
      }

      case "HashDeleteCode": {
        const text = incomingMessage.value;
        const hash = await sha256(await sha256(text));

        output("ReceivedHashedDeleteCode", hash);
        break;
      }

      default:
        logger.error("JS received unkown message from Elm", incomingMessage);
    }
  };
}

async function sha256(text) {
  if (typeof text !== "string") {
    return null;
  }
  try {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  } catch (error) {
    return null;
  }
}
