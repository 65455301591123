import { Elm } from "./Main.elm";
import autosize from "autosize";
import initPB from "./adapters/pocketbase";
import initHasura from "./adapters/graphql-http-ws";

const app = Elm.Main.init({
  flags: {
    seed: Date.now(),
    now: Math.round(Date.now()),
    url: window.location.href,
    copy: window.COPY,
    config: {
      appUrlEnglish: process.env.APP_URL,
      appUrlGerman: process.env.DE_APP_URL,
      appUrlSpanish: process.env.ES_APP_URL,
      websiteUrl: process.env.WEBSITE_URL,
      tinyteamUrl: process.env.TINYTEAM_URL,
      donateUrl: process.env.DONATE_URL,
      feedbackFormUrl: process.env.FEEDBACK_FORM_URL,
      logoUrl: new URL("images/konsens.svg", import.meta.url).href,
    },
  },
  node: document.getElementById("app"),
});

const idLength = window.location.pathname.substring(1).length;

if (idLength === 36) {
  app.ports.toJS.subscribe(
    initHasura((tag, value) => app.ports.fromJS.send({ tag, value }))
  );
} else {
  app.ports.toJS.subscribe(
    initPB((tag, value) => app.ports.fromJS.send({ tag, value }))
  );
}

app.ports.selectText.subscribe((identifier) => {
  document.querySelector(identifier).select();
});

app.ports.copyToClipboard.subscribe(async ([elementId, text]) => {
  const button = document.getElementById(elementId);

  await navigator.clipboard.writeText(text);
  button.querySelector("div").classList.remove("opacity-0");
  button.querySelector("button").classList.add("opacity-0");
  setTimeout(() => {
    button.querySelector("div").classList.add("opacity-0");
    button.querySelector("button").classList.remove("opacity-0");
  }, 2000);
});

app.ports.showToast.subscribe(async (message) => {
  const toast = document.getElementById("toast");
  if (message !== "") {
    toast.lastElementChild.innerHTML = message;
  } else {
    toast.lastElementChild.innerHTML =
      "Sorry, someting went wrong. Please try again.";
  }

  if (!toast.classList.contains("opacity-0")) {
    return;
  }
  toast.classList.remove("opacity-0", "-translate-y-1/2");
  setTimeout(() => {
    toast.classList.add("opacity-0", "-translate-y-1/2");
  }, 5000);
});

app.ports.pressedFoundErrorButton.subscribe(async (questionId) => {
  await loadExternalScript("https://tally.so/widgets/embed.js");
  const Bowser = await import("bowser");
  const { browser, os } = Bowser.parse(window.navigator.userAgent);

  Tally.openPopup("nrje5l", {
    layout: "modal",
    width: 500,
    hideTitle: true,
    hiddenFields: {
      question_id: questionId,
      browser: [browser.name, browser.version].join(" "),
      os: [os.name, os.versionName, os.version].join(" "),
    },
  });
});

app.ports.disableScrolling.subscribe((boolean) => {
  document.body.classList.toggle("disable-scrolling", boolean);
});

app.ports.updateUrl.subscribe((url) => {
  if (url.startsWith("http")) {
    window.location.href = url;
  } else {
    history.replaceState(null, "", url);
  }
});

app.ports.updateTitle.subscribe((title) => {
  document.title = title + " | Let’s konsens it!";
});

class TextareaAutosize extends HTMLElement {
  connectedCallback() {
    const textarea = this.querySelector("textarea");
    if (textarea) {
      autosize(textarea);
    }
  }
}

customElements.define("textarea-autosize", TextareaAutosize);

async function loadExternalScript(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.onload = resolve;
    script.onerorr = reject;
    document.body.appendChild(script);
  });
}
